import React from 'react'
import styled from 'styled-components'
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import SiteSeo from "../../components/common/SiteSeo"
import Solution from "../../layouts/solution"
import Floww from "../../components/home/Floww"
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function AfterSale({ setIsTaskToUs }) {
  return (
    <Solution>
      <SiteSeo title="Pi R SQUARE Limited | We Draw a Circle by Formula." />
      <Wrapper>
      <div id="AfterSale">
        <div className="navbar">
          <AnchorLink to="/#what-we-do" className="back-button">Back to Main Page</AnchorLink>
        </div>
        <div className="container">
          <div className="detail">
            <p class="brand">Floww</p>
            <h1>After Sale</h1>
            <p><strong>Warranty and Maintenance Service</strong></p>
          </div>

          <div className="show-reel">
            <span className="circle"></span>
            <Carousel
              autoPlay={true}
              interval={3000}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              emulateTouch={true}
              showArrows={false}
            >
                <div>
                  <img src="/images/solutions/aftersale1.png" alt="" className="regular"/>
                  <img src="/images/solutions/aftersale1retina.png" alt="" className="retina"/>
                </div>
                <div>
                    <img src="/images/solutions/aftersale2.png" alt="" className="regular"/>
                    <img src="/images/solutions/aftersale2retina.png" alt="" className="retina"/>
                </div>
                <div>
                    <img src="/images/solutions/aftersale3.png" alt="" className="regular"/>
                    <img src="/images/solutions/aftersale3retina.png" alt="" className="retina"/>
                </div>
            </Carousel>
          </div>
          
          <div className="key-feature">
              <p>Warranty and maintenance can be the key of success in your after sale service. If your staffs still spend a lot of time to manage task’s schedule, forget to remind or make appointment to do job for your services ahead. Here is the solution to transform your business.</p>
              <br/>
            <div>
              <p><strong>With floww after sale service you can use it for:</strong></p>
            </div>
            
            <div>
              <ol>
                  <li>Create and tracking all maintenance, installation, fixing request
                  <br /> tasks for customer and technician or your vendors.</li>
                <li>Manage your stock or inventory.</li>
                <li>Automatically create preventive maintenance (PM) tasks ahead<br />  of schedule and remind you to confirm them right before the date.</li>
                <li>Automatically manage the warranty and remind when it is getting<br />  close to the expiration date.</li>
                <li>Manage staff and customer information.</li>
                <li>Reporting tasks and staff’s performance.</li>
                </ol>
              </div>
              <br/>
              <p>Floww after sale service can connect directly with many ERP and your accounting software. Making all implementation seamless integration to your existing software.</p>
          </div>

        </div>

      </div>
      </Wrapper>
      <Floww />
      </Solution> 
  )
}

const Wrapper = styled.div`
  background-color: #FFFFFF;
  padding: 5rem 2rem;
  color:#111111;
  padding-top: 120px;
  padding-bottom: 100px;
  .navbar {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 6rem;
  }
  .brand {
    font-weight: 600;
    margin-bottom: 4px;
    color: #87CCE6;
  }
  a.back-button {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background-color: transparent;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    color: #111111;
    :active, :focus {
      color: inherit;
    }
    ::before {
      padding-right: 10px;
      content: "";
      display: inline-block;
      width: 20px;
      height: 12px;
      background-image: url('/images/back-arrow.svg');
      background-repeat: no-repeat;
      background-position: center left;
    }
  }
  button.talk-to-us {
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 600;
    padding: 13px 34px;
    color: #FFFFFF;
    background-color: #324B64;
    border-radius: 50px;
    opacity: 0.8;
    transition: 0.5s;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .carousel ul.control-dots li.dot {
    background: #FFFFFF;
    opacity: 1 !important;
  }
  .carousel ul.control-dots li.selected {
    background: #C5283F !important;
    opacity: 1 !important;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    @media screen and (min-width: 992px) {
     display: grid;
      grid-template-columns: auto 50%;
      grid-template-areas:
      'detail      showreel'
      'keyfeature  showreel';
    }

  }
  h1 {
    font-size: 4rem;
    font-weight: 600;
     @media screen and (min-width: 768px) {
      font-size: 5.6rem;
    }
  }
  h1 ~ p {
    margin-bottom: 10px;
  }
  strong {
    font-weight: 600;
    line-height: 140%;
  }
  p,li {
    line-height: 180%;
  }
  .show-reel {
    grid-area: showreel;
    text-align: center;
    padding: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span.circle {
      background-color: #324A64;
      display: inline-block;
      width: 400px;
      height: 400px;
      position: absolute;
      border-radius: 50%;
    }
    img {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
    img.regular {
      display: block;
    }
    img.retina {
      display: none;
    }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
      img.regular {
      display: none;
      }
      img.retina {
        display: block;
      }
    }
  }
  .detail {
    grid-area: detail;
    margin-bottom: 2rem;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  .key-feature {
    grid-area: keyfeature;
    margin-top: 2rem;
    @media screen and (min-width: 768px) {
      margin-top: 0;
    }
    @media screen and (max-width: 991px) {
      br {display: none}
    }
    p {
      margin-bottom: 1rem;
    }
    div ~ div {
      border-left: 1px solid #CCCCCC;
      padding-left: 4rem;
      ul {
        padding-left: 2rem;
      }
      li {
        list-style: decimal;
        margin-bottom: 12px;
        padding-left: 10px;
        margin-left: 20px;
      }
    }
  }
`